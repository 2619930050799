import * as bootstrap from 'bootstrap'
import * as Popper from "@popperjs/core"

 
const copyrightTooltip = new bootstrap.Tooltip('#copyright', {
    boundary: document.body // or document.querySelector('#boundary')
  })

const languageTooltip = new bootstrap.Tooltip('#translate', {
    boundary: document.body // or document.querySelector('#boundary')
  })

const carousel = new bootstrap.Carousel('#myCarousel')

